import { TextProps } from '@chakra-ui/react'
import { TextFieldComponent } from '@/components/atoms'

export type DataTextFieldProps = {
  isParagraph?: boolean
  label?: string
  labelTextProps?: TextProps
  tooltipMessage?: string | React.ReactNode
  value?: string | number
  valueTextProps?: TextProps
}

const DataTextField = ({
  isParagraph,
  label,
  labelTextProps,
  tooltipMessage,
  value,
  valueTextProps,
}: DataTextFieldProps) => {
  if (!value && value !== 0) return null
  return (
    <TextFieldComponent
      isParagraph={isParagraph}
      label={label}
      labelTextProps={labelTextProps}
      tooltipMessage={tooltipMessage}
      value={value}
      valueTextProps={valueTextProps}
    />
  )
}

export default DataTextField
