import { HStack, Stack, Text, TextProps } from '@chakra-ui/react'
import HelpTextPopover from '@/components/atoms/HelpTextPopover'

export type TextFieldProps = {
  isParagraph?: boolean
  label?: string
  labelTextProps?: TextProps
  tooltipMessage?: string | React.ReactNode
  value?: string | number
  valueTextProps?: TextProps
}

const TextFieldComponent = ({
  isParagraph,
  label,
  labelTextProps,
  tooltipMessage,
  value,
  valueTextProps,
}: TextFieldProps) => (
  <Stack
    spacing={isParagraph ? 1 : 2}
    direction={isParagraph ? 'column' : 'row'}
    lineHeight={5}
  >
    {!!label && (
      <HStack spacing={1} width='fit-content'>
        <HelpTextPopover tooltipMessage={tooltipMessage} />
        <Text
          fontSize='sm'
          color='black'
          fontWeight='semibold'
          whiteSpace='nowrap'
          {...labelTextProps}
        >
          {label}:
        </Text>
      </HStack>
    )}
    <Text
      fontSize='sm'
      color='gray.600'
      overflow='hidden'
      textOverflow='ellipsis'
      whiteSpace='nowrap'
      {...valueTextProps}
    >
      {value}
    </Text>
  </Stack>
)

export default TextFieldComponent
