import { Flex, HStack } from '@chakra-ui/react'

import { useAppSelector } from '@/hooks'
import { selectSidebarIsOpen } from '@/store/sidebarSlice'

import HeaderBreadcrumbs from '@/components/molecules/HeaderBreadcrumbs'
import HeaderMenu from '@/components/molecules/HeaderMenu'
import NotificationMenu from '@/components/molecules/NotificationMenu'

type HeaderProps = {
  isHomePage: boolean
}

const Header = ({ isHomePage }: HeaderProps) => {
  const isSidebarOpen = useAppSelector(selectSidebarIsOpen)
  return (
    <Flex
      alignItems='center'
      bgColor={isHomePage ? 'trasparent' : 'white'}
      height='72px'
      justifyContent='space-between'
      paddingLeft={isSidebarOpen ? '260px' : '78px'}
      paddingRight={5}
      position={isHomePage ? 'relative' : 'sticky'}
      top='0'
      transition='padding-left 0.150s ease'
      zIndex='docked'
      boxShadow='sm'
    >
      <HeaderBreadcrumbs isHomePage={isHomePage} />
      <HStack spacing={6}>
        <NotificationMenu isHomePage={isHomePage} />
        <HeaderMenu isHomePage={isHomePage} />
      </HStack>
    </Flex>
  )
}

export default Header
